* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}
